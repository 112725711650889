import "./admin_form_media_select.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import feather from "feather-icons";
import "details-dialog-element";
import "details-dialog-element/index.css";
import "instantsearch.css/themes/reset.css";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, searchBox, hits } from "instantsearch.js/es/widgets";

export class Controller extends BaseController {
  static targets = [
    "items",
    "modal",
    "modalContent",
    "summary",
    "template",
    "tid",
    "searchBox",
    "hits"
  ];
  static values = {
    applicationId: String,
    apiKey: String,
    indexName: String
  };

  connect() {
    this.kinds = JSON.parse(this.element.getAttribute("data-kinds"));
  }

  loading(e) {
    e.target.closest(".admin-button").classList.add("is-loading");
  }

  load(e) {
    this.modalTarget.classList.remove("is-loading");
    const [, , xhr] = e.detail;
    this.modalContentTarget.innerHTML = xhr.response;
    this.modalContentTarget.scrollTop = 0;
    feather.replace();
    this.setupEvents();
    this.initAlgolia();
    this.initWidgets();
    this.search.start();
  }

  loadAndOpen(e) {
    e.target.closest(".admin-button").classList.remove("is-loading");
    this.load(e);
    this.open();
  }

  open() {
    this.summaryTarget.click();
  }

  close() {
    this.summaryTarget.click();
  }

  setupEvents() {
    this.modalContentTarget
      .querySelectorAll(".admin-pagination a")
      .forEach(el => {
        el.setAttribute("data-remote", true);
        el.setAttribute(
          "data-action",
          "ajax:success->admin-form-media-select#load"
        );
        el.addEventListener("click", () =>
          this.modalTarget.classList.add("is-loading")
        );
      });
  }

  addMedia(e) {
    e.preventDefault();
    const medium = e.target.closest(".admin-media").cloneNode(true);
    const tid = medium.getAttribute("data-tid");
    this.addSelectedMedium(medium, tid);
    this.close();
  }

  removeMedia(e) {
    e.preventDefault();
    const medium = e.target.closest(".admin-form-media-select-item").firstChild;
    const destroy = !medium.classList.contains("is-destroyed");
    medium.classList.toggle("is-destroyed", destroy);

    if (this.hasTidTarget) {
      if (destroy) {
        this.savedTid = this.tidTarget.value;
        this.tidTarget.value = "";
      } else {
        this.tidTarget.value = this.savedTid;
        this.savedTid = null;
      }
    } else {
      medium.parentNode.querySelector(
        'input[name*="_tids"]'
      ).disabled = destroy;
    }
  }

  addSelectedMedium(medium, tid) {
    if (this.hasTidTarget) {
      this.tidTarget.value = tid;
      this.itemsTarget.innerHTML = "";
    } else {
      const input = this.templateTarget.innerHTML.replace(/TID/g, tid);
      this.itemsTarget.insertAdjacentHTML("beforeend", input);
    }

    this.itemsTarget.appendChild(medium);
  }

  initAlgolia() {
    if (this.search) this.search.dispose();

    const searchClient = algoliasearch(
      this.applicationIdValue,
      this.apiKeyValue
    );

    const hitsContainer = this.hitsTarget;
    const { originalItems } = this;

    this.search = instantsearch({
      indexName: this.indexNameValue,
      // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#algolia-search-helper
      searchFunction(helper) {
        if (helper.state.query === "") {
          hitsContainer.style.display = "none";
          originalItems.style.display = "";
        } else {
          hitsContainer.style.display = "";
          originalItems.style.display = "none";
        }

        helper.search();
      },
      searchClient
    });

    const kindsFilter = this.kinds.map(e => `medium_type:${e}`);

    // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#configure
    this.search.addWidgets([
      configure({
        hitsPerPage: 30,
        facetFilters: [kindsFilter] // nested array = OR
      })
    ]);
  }

  initWidgets() {
    this.searchBoxTarget.innerHTML = "";
    this.hitsTarget.innerHTML = "";

    this.search.addWidgets([
      searchBox({
        container: this.searchBoxTarget
      })
    ]);

    this.search.addWidgets([
      hits({
        cssClasses: {
          list: "admin-items-grid"
        },
        container: this.hitsTarget,
        templates: {
          item: `<a href="{{admin_path}}" class="admin-media" data-tid="{{medium_tid}}" data-action="admin-form-media-select#addMedia">
            <img src="{{thumbnail}}" />
            <div class="admin-media-content">
              <strong class="admin-media-title" title="{{title}}">
                {{#helpers.highlight}}{ "attribute": "title" }{{/helpers.highlight}}
              </strong>
              <div class="admin-media-text">{{details}}</div>
            </div>
          </a>`
        }
      })
    ]);
  }

  get originalItems() {
    return this.modalContentTarget.querySelector(".admin-items-grid");
  }
}

import {
  Highlight,
  ReverseHighlight,
  ReverseSnippet,
  Snippet,
} from "instantsearch.js/es/helpers/components";
import { html, render } from "htm/preact";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";

const components = { Highlight, ReverseHighlight, ReverseSnippet, Snippet };

const renderInfiniteHits = (renderOptions, isFirstRender) => {
  const {
    items,
    widgetParams,
    showPrevious,
    isFirstPage,
    showMore,
    isLastPage,
  } = renderOptions;

  if (isFirstRender) {
    widgetParams.container.innerHTML =
      '<div class="c-ihedd--cards-wall-root" data-controller="ihedd--cards-wall"><div class="c-ihedd--cards-wall-container"><div class="c-ihedd--cards-wall-column"></div><div class="c-ihedd--cards-wall-column"></div></div></div>';

    const previousButton = document.createElement("button");
    previousButton.className = "previous-button c-ihedd--search--hits-more";
    previousButton.textContent = "Show previous";

    previousButton.addEventListener("click", () => {
      showPrevious();
    });

    const nextButton = document.createElement("button");
    nextButton.className = "next-button c-ihedd--search--hits-more";
    nextButton.textContent = "Show more";

    nextButton.addEventListener("click", () => {
      showMore();
    });

    widgetParams.container.appendChild(previousButton);
    widgetParams.container.appendChild(nextButton);

    return;
  }

  widgetParams.container.querySelector(".previous-button").disabled =
    isFirstPage;
  widgetParams.container.querySelector(".next-button").disabled = isLastPage;

  const firstColEl = widgetParams.container.querySelector(
    ".c-ihedd--cards-wall-column:first-child"
  );
  const firstColItems = items.filter((_, index) => !(index % 2));
  const lastColEl = widgetParams.container.querySelector(
    ".c-ihedd--cards-wall-column:last-child"
  );
  const lastColItems = items.filter((_, index) => index % 2);

  render(
    firstColItems.map((item) =>
      widgetParams.templates.item(item, { html: html, components: components })
    ),
    firstColEl
  );

  render(
    lastColItems.map((item) =>
      widgetParams.templates.item(item, { html: html, components: components })
    ),
    lastColEl
  );
};

const infiniteHitsWall = connectInfiniteHits(renderInfiniteHits);

export default infiniteHitsWall;

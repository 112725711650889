import "./item_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import { useHotkeys } from "stimulus-use/hotkeys";
import hotkeys from "hotkeys-js";

export class Controller extends BaseController {
  static targets = [];

  initialize() {
    this.dropdownTarget = null;
    this.dropdownItemsTarget = null;
    this.leaveTimeout = null;
  }

  connect() {
    // Exit early if we are in a mobile nav
    if (
      this.element
        .closest("nav")
        .classList.contains("c-ihedd--header-mobile-nav")
    ) {
      return;
    }
    this.initKeyboard();
  }

  disconnect() {
    this.dropdownTarget = null;
    this.dropdownItemsTarget = null;
    this.leaveTimeout = null;
  }

  // Accessible Dropdown with keyboard
  // FIXME: This is hardcoded for only one dropdown for now
  initKeyboard() {
    hotkeys.setScope("dropdown-items-closed");
    useHotkeys(this, {
      hotkeys: {
        // Esc
        // - If a subnav is open, close it and set focus on the nav item that controls that subnav.
        esc: {
          handler: this.closeDropdownItems,
          options: {
            scope: "dropdown-items-open",
          },
        },
        // Space, Enter
        // - If focus is on a nav item, open the subnav.
        // - If the subnav is already open, close it.
        "space,enter": {
          handler: (e) => {
            if (e.target.classList.contains("c-ihedd--header--item-dropdown")) {
              e.preventDefault();
              if (e.target.getAttribute("aria-expanded") == "true") {
                this.closeDropdownItems();
              } else {
                this.openDropdownItems(e.target);
              }
            }
          },
        },
        // Down arrow
        // - If focus is on a nav item, open the subnav and set focus on the first link in the subnav.
        // - If focus is on a link in a subnav (not the last one), set focus on the next link in the subnav.
        down: {
          handler: (e) => {
            if (e.target.classList.contains("c-ihedd--header--item-dropdown")) {
              e.preventDefault();

              if (
                this.dropdownTarget &&
                this.dropdownTarget.getAttribute("aria-expanded") == "true"
              ) {
                this.currentDropdownItemsLinks[0].focus();
              } else {
                this.openDropdownItems(e.target);
              }
            } else if (
              e.target.classList.contains("c-ihedd--header--item-child")
            ) {
              e.preventDefault();

              const currentLinkIndex = Array.from(
                this.currentDropdownItemsLinks
              ).indexOf(e.target);

              const nextLink =
                this.currentDropdownItemsLinks[currentLinkIndex + 1];
              if (nextLink) nextLink.focus();
            }
          },
        },
        // Up arrow
        // - If focus is on a nav item and the subnav it controls is open, close the subnav and set focus on the nav item.
        // - If focus is on a link in a subnav (not the first one), set focus on the previous link in the subnav.
        up: {
          handler: (e) => {
            if (e.target.classList.contains("c-ihedd--header--item-dropdown")) {
              e.preventDefault();
              this.closeDropdownItems();
            } else if (
              e.target.classList.contains("c-ihedd--header--item-child")
            ) {
              e.preventDefault();

              const currentLinkIndex = Array.from(
                this.currentDropdownItemsLinks
              ).indexOf(e.target);

              // Focus the Dropdown Link
              if (currentLinkIndex == 0) {
                return this.dropdownTarget.focus();
              }

              const previousLink =
                this.currentDropdownItemsLinks[currentLinkIndex - 1];
              if (previousLink) previousLink.focus();
            }
          },
          options: {
            scope: "dropdown-items-open",
          },
        },
      },
    });
  }

  // Dropdown Link - Actions
  enterDropdownLink(e) {
    this.cancelTimeout();

    const enteringDropdownLink = e.target.closest("a");
    this.openDropdownItems(enteringDropdownLink);
  }

  leaveDropdownLink(e) {
    const enteringDropdownItems =
      e.relatedTarget &&
      !!e.relatedTarget.closest(".c-ihedd--header--item-items");
    if (enteringDropdownItems) return;

    this.closeDropdownItems(false);
  }

  // Dropdown Items
  openDropdownItems(dropdownLink) {
    console.debug("openDropdownItems");
    if (this.dropdownItemsTarget == dropdownLink) return;
    if (this.dropdownItemsTarget) return this.closeDropdownItems(false);
    this.dropdownTarget = dropdownLink;
    this.dropdownItemsTarget = dropdownLink.nextElementSibling;
    this.dropdownItemsTarget.inert = false;
    dropdownLink.setAttribute("aria-expanded", "true");
    hotkeys.setScope("dropdown-items-open");
  }

  closeDropdownItems(giveFocus = true) {
    console.debug("closeDropdownItems");
    if (!this.dropdownItemsTarget) return;
    if (giveFocus) this.dropdownItemsTarget.focus();

    hotkeys.setScope("dropdown-items-closed");
    this.dropdownTarget.setAttribute("aria-expanded", "false");
    this.dropdownItemsTarget.inert = true;
    this.dropdownTarget = null;
    this.dropdownItemsTarget = null;
  }

  // Dropdown Items - Actions
  enterDropdownItems() {
    this.cancelTimeout();
  }

  leaveDropdownItems() {
    console.debug("leaveDropdownItems");
    this.closeDropdownItemsAfterTimeout();
  }

  // Utilities
  closeDropdownItemsAfterTimeout() {
    console.debug("close after timeout");
    this.leaveTimeout = setTimeout(() => {
      console.debug("timeout!");
      this.closeDropdownItems(false);
    }, 350);
  }

  cancelTimeout() {
    if (!this.leaveTimeout) return;

    clearTimeout(this.leaveTimeout);
    this.leaveTimeout = null;
  }

  // Getters
  get currentDropdownItemsLinks() {
    return this.dropdownItemsTarget.querySelectorAll(
      ".c-ihedd--header--item-child"
    );
  }
}

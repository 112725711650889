import "./registration_component.css";
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["financingOption", "selectWithOther"];

  connect() {
    this.financingOptionTargets.forEach((target) => {
      this.applyFinancingConditional(target);
    });

    this.selectWithOtherTargets.forEach((target) => {
      this.applyOtherConditional(target);
    });
  }

  toggleFinancing(e) {
    const { target } = e;
    this.applyFinancingConditional(target);
  }

  toggleOther(e) {
    const { target } = e;
    this.applyOtherConditional(target);
  }

  toggleDisabilityNeed(e) {
    const { target } = e;
    this.applyDisabilityNeedConditional(target);
  }

  applyFinancingConditional(target) {
    this.applyConditional(target, target.checked);
  }

  applyOtherConditional(target) {
    this.applyConditional(target, target.value === "other");
  }

  applyDisabilityNeedConditional(target) {
    this.applyConditional(target, target.value === "true");
  }

  applyConditional(target, condition) {
    const { conditionalId } = target.dataset;
    const conditional = document.getElementById(conditionalId);

    if (!conditional) return;

    const conditionalInputs = conditional.querySelectorAll(
      "input, select, textarea"
    );

    if (condition) {
      conditional.style.display = "flex";
      conditionalInputs.forEach((i) => (i.disabled = false));
    } else {
      conditional.style.display = "none";
      conditionalInputs.forEach((i) => (i.disabled = true));
    }
  }
}

import "./admin_form_relationship_select.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.css";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";

export class Controller extends BaseController {
  static targets = ["select"];
  static values = {
    api: Boolean,
    apiUrl: String,
    id: String,
    applicationId: String,
    apiKey: String,
    model: String,
    indexName: String,
  };

  connect() {
    this.form = this.element.closest("form");
    this.lookupDelay = 100;
    this.lookupTimeout = null;

    if (!this.apiValue) {
      this.initAlgolia();
    }

    this.choices = new Choices(this.selectTarget, {
      duplicateItemsAllowed: false,
      removeItemButton: true,
      searchFloor: 1,
      shouldSort: false,
      searchChoices: false
    });

    // Trigger an API lookup when the user pauses after typing.
    this.selectTarget.addEventListener("search", () => {
      clearTimeout(this.lookupTimeout);
      this.lookupTimeout = setTimeout(this.search.bind(this), this.lookupDelay);
    });

    // Used for updating preview
    this.choices.passedElement.element.addEventListener("change", () => {
      // Disable the select if no choice, so the hidden field can be sent
      this.selectTarget.disabled = (this.choices.passedElement.element.value == "");
      this.choices.clearChoices();
      this.hasChanged();
      this.search();
    });

    // Pre-populate the choices
    this.choices.passedElement.element.addEventListener("showDropdown", () => {
      if (this.choices.input.value !== "") return;

      this.search();
    });
  }

  disconnect() {
    this.choices.destroy();
    this.choices = null;
  }

  initAlgolia() {
    const searchClient = algoliasearch(
      this.applicationIdValue,
      this.apiKeyValue
    );

    // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#algolia-search-helper
    if (this.modelValue === "Tag" || this.modelValue === "Page") {
      this.algolia = algoliasearchHelper(searchClient, this.indexNameValue);
    } else {
      this.algolia = algoliasearchHelper(searchClient, this.indexNameValue, {
        facets: ["kind"]
      });

      this.algolia.addFacetRefinement("kind", this.modelValue);
    }

    this.algolia.on("result", ({ results }) => this.populateOptions(results));
  }

  search() {
    const query = this.choices.input.value;
    const currentItems = this.choices._store.state.items; // eslint-disable-line
    const currentItemsIds = currentItems
      .filter(c => c.active)
      .map(c => c.value);

    if (this.apiValue) {
      const url = new URL(this.apiUrlValue);
      url.searchParams.set("q", query);
      currentItemsIds.forEach(id =>
        url.searchParams.append(`exclude_ids[]`, id)
      );

      fetch(url, {
        credentials: "include"
      })
        .then(response => {
          response.json().then(data => this.populateOptions(data));
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.algolia.setQuery(query);
      this.algolia.removeNumericRefinement(this.idAttribute);

      if (this.idValue) {
        this.algolia.addNumericRefinement(this.idAttribute, "!=", this.idValue);
      }

      currentItemsIds.forEach(id =>
        this.algolia.addNumericRefinement(this.idAttribute, "!=", id)
      );

      this.algolia.search();
    }
  }

  populateOptions(results) {
    if (results.nbHits === 0) this.choices.clearChoices();

    this.choices.setChoices(results.hits, this.idAttribute, "title", true);
  }

  hasChanged() {
    let event;

    if (window.CustomEvent) {
      event = new CustomEvent("input");
    } else {
      event = document.createEvent("input");
      event.initCustomEvent("input", true, true);
    }

    this.form.dispatchEvent(event);
  }

  get idAttribute() {
    if (this.apiValue) {
      return "id";
    }

    if (this.modelValue === "Page") {
      return "model_id";
    }

    return "pageable_id";
  }
}

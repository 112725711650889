export default {
  cssClasses: {
    noRefinementRoot: "c-ihedd--search--refinements-empty",
    list: "c-ihedd--search--refinements-items",
    item: "c-ihedd--search--refinements-item",
    label: "c-ihedd--search--refinements-label",
    category: "c-ihedd--search--refinements-category",
    "category-label": "c-ihedd--search--refinements-category-label",
    delete: "c-ihedd--search--refinements-delete",
  },
};

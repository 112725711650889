import "./header_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["toggle", "nav", "header"];

  connect() {
    this.toggleTarget.addEventListener("click", this.toggle.bind(this));
  }

  disconnect() {
    this.toggleTarget.removeEventListener("click", this.toggle.bind(this));
  }

  toggle() {
    this.toggleTarget.classList.toggle(
      "c-ihedd--learners--header-toggle-active"
    );
    this.navTarget.classList.toggle("c-ihedd--learners--header-nav-active");
    this.headerTarget.classList.toggle("c-ihedd--learners--header-active");
  }
}

import fitvids from "fitvids";
import "./embed.css";

fitvids(".embed", {
  players: "iframe"
});

document.addEventListener("turbo:load", () => {
  fitvids(".embed", {
    players: "iframe"
  });
});

import { refinementList } from "instantsearch.js/es/widgets";
import I18n from "../../../../javascript/i18n";

const refinementListDefaultOptions = {
  cssClasses: {
    root: "c-ihedd--search--filters-panel",
    list: "c-ihedd--search--filters-items",
    item: "c-ihedd--search--filters-item",
    checkbox: "c-ihedd--search--filters-checkbox",
    selectedItem: "c-ihedd--search--filters-selected",
    label: "c-ihedd--search--filters-label",
    count: "c-ihedd--search--filters-count",
    showMore: "c-ihedd--search--filters-more",
  },
  sortBy: ["count", "name:asc"],
  showMore: true,
  templates: {
    showMoreText(data, { html }) {
      return html`<span
        >${data.isShowingMore
          ? I18n.t("search.ui.see_less")
          : I18n.t("search.ui.see_more")}</span
      >`;
    },
  },
};

export default {
  human_kind: {
    widget: refinementList,
    title: I18n.t("search.filters.human_kind"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "human_kind",
    },
  },
  training_topics: {
    widget: refinementList,
    title: I18n.t("search.filters.training_topics"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "training_topics.title",
      templates: {
        item(item, { html }) {
          const { label, color, icon, count, isRefined, cssClasses } = item;
          return html`
            <label class="${cssClasses.label}">
              <input
                type="checkbox"
                class="${cssClasses.checkbox}"
                value="${label}"
                checked="${isRefined}"
              />
              <span
                class="c-ihedd--topic-label-root c-ihedd--topic-label-is-seamless"
                style="--topic-color: var(--${color});"
                ><svg
                  class="c-icon-root c-icon-${icon} c-ihedd--topic-label-icon"
                  role="img"
                  style="color: var(--currentColor); "
                >
                  <use xlink:href="#c-icon-${icon}" /></svg
                ><span class="c-ihedd--topic-label-text">${label}</span></span
              >
              <span class="${cssClasses.count}">${count}</span>
            </label>
          `;
        },
      },
    },
  },
  training_journeys: {
    widget: refinementList,
    title: I18n.t("search.filters.training_journeys"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "training_journeys.name",
    },
  },
  formats: {
    widget: refinementList,
    title: I18n.t("search.filters.formats"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "formats",
    },
  },
  training_kind: {
    widget: refinementList,
    title: I18n.t("search.filters.trainings.human_kind"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "human_kind",
    },
  },
  language: {
    widget: refinementList,
    title: I18n.t("search.filters.language"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "language",
    },
  },
  place: {
    widget: refinementList,
    title: I18n.t("search.filters.place"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "place",
      sortBy: (a, b) => {
        if (b.name == "En ligne" || b.name == "Upcoming") return 1;
        return b.count - a.count;
      },
    },
  },
  trainers: {
    widget: refinementList,
    title: I18n.t("search.filters.trainers"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "trainers.name",
    },
  },
  partners: {
    widget: refinementList,
    title: I18n.t("search.filters.partners"),
    options: {
      ...refinementListDefaultOptions,
      attribute: "partners",
    },
  },
};

import "./toast_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    if (this.element.classList.contains("c-ihedd--toast-fixed")) {
      this.hideToastAfterDelay();
    }
  }

  hideToastAfterDelay() {
    setTimeout(() => {
      this.element.style.opacity = "0";
      setTimeout(() => {
        this.element.remove();
      }, 300);
    }, 3000);
  }
}

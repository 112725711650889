import "./admin_search.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import "instantsearch.css/themes/reset.css";
// import "instantsearch.css/themes/algolia.css";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, searchBox, pagination } from "instantsearch.js/es/widgets";
import { connectHits } from "instantsearch.js/es/connectors";
import hitTemplates from "./hit_templates";

export class Controller extends BaseController {
  static targets = ["searchBox", "hits", "pagination"];
  static values = {
    apiKey: String,
    applicationId: String,
    index: String,
    indexKey: String,
    pageableType: String,
  };

  connect() {
    this.initAlgolia();
    this.initWidgets();
    this.initHits();
    this.search.start();
  }

  disconnect() {
    this.search.dispose();
  }

  initAlgolia() {
    const searchClient = algoliasearch(
      this.applicationIdValue,
      this.apiKeyValue
    );

    const hitsContainer = this.hitsTarget;
    const paginationContainer = this.paginationTarget;
    const { originalItems } = this;

    this.search = instantsearch({
      indexName: this.indexValue,
      // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#algolia-search-helper
      searchFunction(helper) {
        if (helper.state.query === "") {
          hitsContainer.style.display = "none";
          paginationContainer.style.display = "none";
          originalItems.style.display = "";
        } else {
          hitsContainer.style.display = "";
          paginationContainer.style.display = "";
          originalItems.style.display = "none";
        }

        helper.search();
      },
      searchClient
    });

    if (this.pageableTypeValue) {
      // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#configure
      this.search.addWidgets([
        configure({
          facetFilters: `pageable_type:${this.pageableTypeValue}`
        })
      ]);
    }
  }

  initWidgets() {
    this.search.addWidgets([
      searchBox({
        container: this.searchBoxTarget,
        showLoadingIndicator: false
      })
    ]);

    const paginationContainer = this.paginationTarget;
    this.search.addWidgets([
      pagination({
        container: paginationContainer,
        cssClasses: {
          root: "admin-pagination"
        }
      })
    ]);
  }

  initHits() {
    const renderHits = renderOptions => {
      const { hits, widgetParams } = renderOptions;
      const template = hitTemplates[this.indexKey];

      widgetParams.container.innerHTML = hitTemplates[
        `${this.indexKey}_container`
      ](hits, template);
    };

    const customHits = connectHits(renderHits);

    this.search.addWidgets([
      customHits({
        container: this.hitsTarget
      })
    ]);
  }

  hitTemplate(hit) {
    if (!document.hitTemplate) return this.defaultTemplate(hit);

    return document.hitTemplate(hit);
  }

  get indexKey() {
    return this.indexKeyValue || "pages";
  }

  get defaultTemplate() {
    return hit => `<a href="${hit.path}">
      ${instantsearch.highlight({ attribute: "title", hit })}
    </a>`;
  }

  get originalItems() {
    return document.querySelector(
      ".admin-items-table, .admin-items-tree, .admin-items-grid"
    );
  }
}

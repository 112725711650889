import "./newsletter_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["form", "input", "text", "status"];

  // TODO : Fix this JS code

  register(event) {
    event.preventDefault();
    if (!this.hasFormTarget || !this.hasStatusTarget) return;

    this.statusTarget.innerHTML = "";

    this.registerToNewsletter().then((data) => {
      if (data.subscribed) {
        if (this.hasTextTarget) this.textTarget.innerHTML = data.message;
        this.formTarget.style.display = "none";
      } else {
        this.statusTarget.innerHTML = data.message;
        if (this.hasInputTarget) this.inputTarget.focus();
        this.formTarget.style.display = "flex";
      }
    });
  }

  async registerToNewsletter() {
    if (!this.hasFormTarget) return;

    const token = document.querySelector("meta[name='csrf-token']")?.content;
    if (!token) {
      console.error("CSRF token not found");
      return { subscribed: false, message: "Erreur de sécurité" };
    }

    const formData = new FormData(this.formTarget);
    formData.append("authenticity_token", token);

    try {
      const response = await fetch(this.formTarget.action, {
        method: "POST",
        headers: { "X-CSRF-Token": token },
        credentials: "same-origin",
        body: formData,
      });

      return response.json();
    } catch (error) {
      console.error("Error during registration:", error);
      return { subscribed: false, message: "Une erreur s'est produite" };
    }
  }
}

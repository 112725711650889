export default {
  cssClasses: {
    form: "c-ihedd--search--box-form",
    input: "c-ihedd--search--box-input",
    loadingIndicator: "c-ihedd--search--box-loading",
    reset: "c-ihedd--search--box-reset",
    submit: "c-ihedd--search--box-submit",
  },
  placeholder: "Rechercher",
};

import "./header_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["panel"];

  initialize() {
    this.leaveTimeout = null;
  }

  toggle(e) {
    e.target
      .closest("button")
      .classList.toggle("c-ihedd--header-toggle-active");
    this.panelTarget.classList.toggle("c-ihedd--header-mobile-panel-active");
  }
}
